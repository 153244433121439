export const routes = {
  LOGIN: {
    PATH: "auth/login",
    METHOD: "POST",
  },
  SIGN_UP: {
    PATH: "auth/signup",
    METHOD: "POST",
  },

  GET_RESOURCE_URL: {
    PATH: "media/get-url",
    METHOD: "GET",
  },
  DELETE_RESOURCE: {
    PATH: "media/delete-resource",
    METHOD: "DELETE",
  },

  USER_DETAILS: {
    PATH: "user/details",
    METHOD: "GET",
  },
  USER_LISTING: {
    PATH: "user/listing",
    METHOD: "GET",
  },
  USER_UPDATE: {
    PATH: "user/update",
    METHOD: "POST",
  },

  CHAT_LISTING: {
    PATH: "chat/listing",
    METHOD: "GET",
  },
};
